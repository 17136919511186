import { gsap } from 'gsap'

const NAMESPACES = ['index', 'projecten', 'over-mij', 'contact']

export default [
  {
    name: 'right-transition',
    sync: true,
    leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0,
        x: '-100%',
        ease: 'power4.out',
      })
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0,
        x: '100%',
        ease: 'power4.out',
      })
    },
    to: {
      namespace: ['project'],
    },
  },
  {
    name: 'left-transition',
    sync: true,
    leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0,
        x: '100%',
        ease: 'power4.out',
      })
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0,
        x: '-100%',
        ease: 'power4.out',
      })
    },
    from: {
      namespace: ['project'],
    },
  },
  {
    name: 'down-transition',
    sync: true,
    leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0,
        y: '-100%',
        ease: 'power4.out',
      })
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0,
        y: '100%',
        ease: 'power4.out',
      })
    },
    from: {
      custom: (data) => {
        const links = NAMESPACES
        return (
          links.indexOf(data.current.namespace) <
          links.indexOf(data.next.namespace)
        )
      },
      namespace: NAMESPACES,
    },
  },
  {
    name: 'up-transition',
    sync: true,
    leave(data) {
      return gsap.to(data.current.container, {
        opacity: 0,
        y: '100%',
        ease: 'power4.out',
      })
    },
    enter(data) {
      return gsap.from(data.next.container, {
        opacity: 0,
        y: '-100%',
        ease: 'power4.out',
      })
    },
  },
]
