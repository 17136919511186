import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import barba from '@barba/core'
import SplitType from 'split-type'

import galleryLayout from './modules/galleryLayout'
import transitions from './modules/page-transitions'
import photoswipe from './modules/photoswipe'

gsap.registerPlugin(ScrollTrigger)
const matchMedia = gsap.matchMedia()

jQuery(function ($) {
  barba.init({
    debug: false,
    prevent: ({ el }) => el.href.indexOf('wp-admin') != -1,
    transitions,
    views: [
      {
        namespace: 'index',
        beforeEnter(data) {
          const $gallery = $(data.next.container).find('#gallery')
          galleryLayout($gallery)
          photoswipe($gallery.get(0))
          prepareBatchAnim(data, '.gallery-item')
        },
        afterEnter(data) {
          scrollBatchAnim(data, '.gallery-item')
        },
      },
      {
        namespace: 'projecten',
        beforeEnter(data) {
          prepareBatchAnim(data, '.project-item')
        },
        afterEnter(data) {
          scrollBatchAnim(data, '.project-item')
        },
      },
      {
        namespace: 'project',
        beforeEnter(data) {
          photoswipe(data.next.container)
          prepareBatchAnim(data, '.project-gallery-item')
        },
        afterEnter(data) {
          scrollBatchAnim(data, '.project-gallery-item')
        },
      },
      {
        namespace: 'contact',
        beforeEnter(data) {
          const $form = $(data.next.container).find('#contact-form')
          const $successMsg = $form.find('.form-success')

          $form
            .find('input, textarea')
            .on('focus', function () {
              $(this).closest('.form-item').addClass('is-focused')
            })
            .on('blur', function () {
              $(this).closest('.form-item').removeClass('is-focused')
            })
            .on('input', function () {
              if ($(this).val()) {
                $(this).closest('.form-item').addClass('is-filled')
              } else {
                $(this).closest('.form-item').removeClass('is-filled')
              }
            })

          $form
            .find('textarea')
            .each(function () {
              this.style.height = `${this.scrollHeight}px`
            })
            .on('input', function () {
              this.style.height = 0
              this.style.height = `${this.scrollHeight}px`
            })

          $form.validate({
            submitHandler: function (form) {
              const formId = $form.data('id')
              const formData = new FormData(form)
              $.ajax({
                url: `/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`,
                type: 'POST',
                enctype: 'multipart/form-data',
                data: formData,
                processData: false,
                contentType: false,
                cache: false,
                success: function (data) {
                  if (data.status === 'mail_sent') {
                    $successMsg.fadeIn()
                    $form.find('input, textarea').val('')
                  }
                },
                error: function (data) {
                  console.log(data)
                },
              })
            },
            highlight: function (element) {
              $(element).closest('.form-item').addClass('is-error')
            },
            unhighlight: function (element) {
              $(element).closest('.form-item').removeClass('is-error')
            },
            errorPlacement: function (error, element) {
              error.insertAfter(element.parent())
            },
          })
        },
      },
    ],
  })

  barba.hooks.enter((data) => {
    window.scrollTo(0, 0)
    textAppear(data.next.container)
  })
  barba.hooks.after(() => {
    ga('set', 'page', window.location.pathname)
    ga('send', 'pageview')
  })

  function isMobile() {
    return window.innerWidth <= 767
  }

  function prepareBatchAnim(barbaData, selector) {
    const elements = $(barbaData.next.container).find(selector)
    gsap.set(elements, { opacity: 0, y: 100 })
  }

  function scrollBatchAnim(barbaData, selector) {
    const elements = $(barbaData.next.container).find(selector)
    setTimeout(
      () => {
        ScrollTrigger.batch(elements, {
          onEnter: (batch) => {
            gsap.to(batch, {
              opacity: 1,
              y: 0,
              stagger: 0.1,
            })
          },
          once: true,
        })
      },
      barbaData.current.container || isMobile() ? 1 : 1500
    )
  }

  function textAppear(container = document) {
    new SplitType('.text-appear', {
      types: 'chars',
      tagName: 'span',
    })

    $(container)
      .find('.text-appear')
      .each(function () {
        gsap.from($(this).find('.char'), {
          opacity: 0,
          y: '100%',
          stagger: 0.1,
          ease: 'power3.out',
        })
      })
  }

  function initNavbar() {
    let isOpen = false
    const $nav = $('.mobile-nav')
    const $menu = $('.mobile-nav__menu')

    const close = () => {
      $nav.removeClass('open')
      $menu.fadeOut()
      isOpen = false
    }
    const open = () => {
      $nav.addClass('open')
      $menu.fadeIn()
      isOpen = true
    }

    $('.mobile-nav__bar-btn').on('click', function () {
      if (isOpen) {
        close()
      } else {
        open()
      }
    })

    // Show and hide navbar on scroll
    matchMedia.add('(max-width: 767px)', () => {
      const showAmin = gsap
        .fromTo(
          '.mobile-nav__bar',
          {
            y: -100,
            duration: 0.2,
          },
          { y: 0 }
        )
        .progress(1)

      ScrollTrigger.create({
        id: 'mobile-nav',
        start: 'top top',
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAmin.play() : showAmin.reverse()
        },
      })
    })

    barba.hooks.beforeEnter((data) => {
      console.log('beforeEnter')
      setCurrentNavbarItem()
      close()
    })

    setCurrentNavbarItem()
    navbarAnimation()
  }

  function navbarAnimation() {
    const tl = gsap.timeline()
    if (!isMobile()) {
      tl.set('.side-nav .menu-item', { opacity: 0, y: 20 })
      tl.set(
        [
          '.side-nav .branding',
          '.side-nav .nav-socials',
          '.side-nav .nav-footer',
        ],
        {
          opacity: 0,
        }
      )
      tl.from('.side-nav', {
        x: -200,
        duration: 2,
        ease: 'power4.out',
      })
      tl.to(
        '.side-nav .branding',
        { opacity: 1, duration: 1, ease: 'power4.out' },
        '-=1.5'
      )
      tl.to(
        '.side-nav .menu-item',
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: 'power4.out',
        },
        '-=1.4'
      )
      tl.to(
        '.side-nav .nav-footer',
        { opacity: 1, duration: 1, ease: 'power4.out' },
        '-=1.0'
      )
      tl.to(
        '.side-nav .nav-socials',
        { opacity: 1, duration: 1, ease: 'power4.out' },
        '-=0.8'
      )
    }
  }

  function setCurrentNavbarItem() {
    const currentPath = window.location.href

    $('.menu-item').each(function () {
      const $this = $(this)
      const $link = $this.find('a')
      if ($link.attr('href') == currentPath) {
        $this.addClass('is-active')
      } else {
        $this.removeClass('is-active')
      }
    })
  }

  // INIT NAVBAR
  initNavbar()
  textAppear()
})
