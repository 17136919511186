import justifiedLayout from 'justified-layout'

const PADDING = 24
const SPACING = 18

export default function ($gallery) {
  const $galleryItems = $gallery.find('.gallery-item')
  setLayout($gallery, $galleryItems)

  jQuery(function ($) {
    $(window).on('resize', () => {
      setLayout($gallery, $galleryItems)
    })
  })
}

function setLayout($gallery, $galleryItems) {
  const input = $galleryItems.get().map((el) => ({
    width: el.dataset.pswpWidth,
    height: el.dataset.pswpHeight,
  }))
  const output = justifiedLayout(input, {
    containerWidth: $gallery.width(),
    containerPadding: PADDING,
    boxSpacing: SPACING,
  })
  $gallery.height(output.containerHeight)
  output.boxes.forEach((box, index) => {
    jQuery(function ($) {
      const $item = $($galleryItems[index])
      $item.css({
        width: box.width,
        height: box.height,
        top: box.top,
        left: box.left,
      })

      $item.find('.gallery-item__img').lazy()
    })
  })
}
