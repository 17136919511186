import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js'

import closeSVG from 'bundle-text:../../img/close.svg'
import arrowPrevSVG from 'bundle-text:../../img/arrowPrev.svg'
import arrowNextSVG from 'bundle-text:../../img/arrowNext.svg'

const PADDING = 24

export default function (elem) {
  const lightbox = new PhotoSwipeLightbox({
    gallery: elem,
    children: 'a',
    zoom: false,
    counter: false,
    arrowPrevSVG,
    arrowNextSVG,
    closeSVG,
    bgOpacity: 1,
    padding: { top: PADDING, bottom: PADDING, left: PADDING, right: PADDING },
    pswpModule: async () => await import('photoswipe'),
  })

  lightbox.on('contentLoad', (e) => {
    const { content } = e
    if (content.type === 'video') {
      e.preventDefault()

      content.element = document.createElement('div')
      content.element.className = 'pswp__video-wrapper'

      const video = document.createElement('div')
      video.className = 'pswp__video'
      content.element.appendChild(video)

      const iframe = document.createElement('iframe')
      iframe.setAttribute('frameborder', '0')
      iframe.setAttribute('allowfullscreen', '')
      iframe.setAttribute('allowscriptaccess', 'always')
      const id = getYoutubeId(content.data.src)
      iframe.src = `//www.youtube.com/embed/${id}?enablejsapi=1`
      video.appendChild(iframe)
    }
  })

  lightbox.on('change', () => {
    jQuery(function ($) {
      $('.pswp__video iframe').each(function () {
        this.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          '*'
        )
      })
    })
  })

  lightbox.init()
}

function getYoutubeId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return 0
  }
}
